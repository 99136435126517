<template>
    <div>

        <div class="row">

            <div class="col-sm-6 text-center ff" style="height:100%">
                <div>
                    <h2 class="mt-20 ">Order has been Confirmed Successfully  <i class="fas fa-check-circle text-light"></i>
</h2>
<div v-if="order.driver!==null">
    <h6 class="mt-20">
        Driver: <b-badge > {{order.driver.full_name}} </b-badge>
        </h6> 
        <h6 class="mt-5">
        Driver Phone: <a :href="'tel:'+order.driver.phone"><b-badge > {{order.driver.phone}} </b-badge></a> 
        <a :href="'https://api.whatsapp.com/send?phone=962' + order.driver.phone.substring(1)"><b-badge  class="ml-3" >
            Whatsapp<i class="fab fa-whatsapp text-dark ml-2"></i></b-badge></a>
        </h6> 
    
</div>
<div v-else>
    <h6 class="mt-20">
        Driver: <b-badge > - </b-badge>
        </h6> 
        <h6 class="mt-5">
        Driver Phone: <b-badge > - </b-badge>
        <a ><b-badge  class="ml-3" >
            Whatsapp<i class="fab fa-whatsapp text-dark ml-2"></i></b-badge></a>
        </h6> 
    
</div>
<div class="mt-5">
    <h6>

    
    <span>Delivery Fees :<b-badge  class="ml-3"> {{order.fees}} JOD </b-badge></span> 
    <span class="ml-5">Amount :<b-badge  class="ml-3"> {{order.amount}} JOD</b-badge></span> 
    </h6>

</div>




                </div>
                


                
            </div>
            <div class="col-sm-6 image">
                
        
      
            </div>
            
        </div>

    </div>
</template>

<script>
//import s from '../../../../public/media/svg/illustrations/bike.svg'
export default {
    
    name:"ConfirmedOrder",
    async mounted(){
        
        this.order = await this.$store.dispatch('orders/getOne', this.$route.params.id);

        console.log(this.order)

    },
    data(){
        return{
            order:'',
            
        }
    },
    methods:{
        /*sendWhatsapp(){
            this.$router.push({path: `https://api.whatsapp.com/send?${this.phone}`})

        },*/
    }
   

}
</script>

<style scoped>
.image{
    background-image: url('delivery.svg');
    background-size: cover;
    position: fixed;
      bottom: 0;
      height: 100%;
      right: 0;
    
    
}

.ff{
    background-color: #a8a8a8;
   position: fixed;
      top: 0;
      height: 100%;
      left: 0;
}





@media only screen and (max-width: 600px) {
  .image{
      position: fixed;
      bottom: 0;
      height: 50%;
  }
}
</style>